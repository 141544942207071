import Utils from './utils.js';

export default class Config {
  constructor() {
    throw new Error('AbstractClassError');
  }
}

Config.customComponentsNames = [];
Config.desktopCameraHeight = 1;
Config.markerScale = 1;
Config.modelScale = 0.00065;
Config.modelScale *= Utils.getParamValue('scale') || 1;
Config.musicFadeOutDuration = Utils.getParamValue('musicFadeOutDuration') || 6;
Config.musicFadeOutDuration = Math.max(0, Config.musicFadeOutDuration);
