const { debug } = AFRAME.utils;
const { coordinates } = AFRAME.utils;

const warn = debug('components:look-at:warn');
const isCoordinates = coordinates.isCoordinates || coordinates.isCoordinate;

delete AFRAME.components['look-at'];

const lookAtComponent = {
  schema: {
    default: '',

    parse(value) {
      // A static position to look at.
      if (isCoordinates(value) || typeof value === 'object') {
        return coordinates.parse(value);
      }
      // A selector to a target entity.
      return value;
    },

    stringify(data) {
      if (typeof data === 'object') {
        window.console.log('lookAtComponent typeof data === object:');
        return coordinates.stringify(data);
      }
      return data;
    },
  },

  init() {
  },

  update() {
    const self = this;
    const target = self.data;
    const { object3D } = self.el;

    // No longer looking at anything (i.e., look-at="").
    if (!target || (typeof target === 'object' && !Object.keys(target).length)) {
      return self.remove();
    }

    // Look at a position.
    if (typeof target === 'object') {
      return object3D.lookAt(new THREE.Vector3(target.x, target.y, target.z));
    }

    // Assume target is a string.
    // Query for the element, grab its object3D, then register a behavior on the scene to
    // track the target on every tick.
    const targetEl = self.el.sceneEl.querySelector(target);
    if (!targetEl) {
      warn(`"${target}" does not point to a valid entity to look-at`);
      return null;
    }
    if (!targetEl.hasLoaded) {
      return targetEl.addEventListener('loaded', () => {
        self.beginTracking(targetEl);
      });
    }
    return self.beginTracking(targetEl);
  },

  tick() {
    const vec3 = new THREE.Vector3();

    // /////////// return (t) => {
    // Track target object position. Depends on parent object keeping global transforms up
    // to state with updateMatrixWorld(). In practice, this is handled by the renderer.
    let target;
    const { target3D } = this;
    const { object3D } = this.el;
    let { vector } = this;

    if (target3D) {
      target3D.getWorldPosition(vec3);
      if (this.el.getObject3D('camera')) {
        // Flip the vector to -z, looking away from target for camera entities. When using
        // lookat from THREE camera objects, this is applied for you, but since the camera is
        // nested into a Object3D, we need to apply this manually.
        // vector.subVectors(object3D.position, vec3).add(object3D.position);
      } else {
        vector = vec3;
      }
      object3D.lookAt(vector);
    }
    // /////////// }
  },

  beginTracking(targetEl) {
    this.target3D = targetEl.object3D;
    // window.console.log('lookAtComponent (targetEl)() target3D:', this.target3D);
  },
};

export { lookAtComponent };
