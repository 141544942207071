// See https://aframe.io/docs/1.0.0/components/text.html#custom-fonts
import font from '../../assets/fonts/Roboto.fnt';
import fontImage from '../../assets/fonts/Roboto.png';

const textFontComponent = () => ({
  schema: {
    color: { default: '#ffffff' },
    value: { default: 'Text' },
    fontWidth: { default: 0.8 },
  },

  init() {
    this.el.textFontComponent = this;

    const textAttribute = `
      value: ${this.data.value};
      width: ${this.data.fontWidth};
      color: ${this.data.color};
      font: ${font};
      fontImage: ${fontImage};
      shader: sdf;
      alphaTest: 0.99;
      align: center;
      baseline: center;
      anchor: center;
    `;
    this.el.setAttribute('text', textAttribute);

    this.textAttr = this.el.getAttribute('text'); // Reference
  },

  updateText(newText = '') {
    this.textAttr.value = newText;
    this.el.setAttribute('text', this.textAttr);
  },
});

export { textFontComponent };
