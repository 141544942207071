/* eslint-disable no-lonely-if */
import gsap from 'gsap';
import Config from './config.js';
import Utils from './utils.js';

const audioManagerComponent = {

  init() {
    console.log('audioManagerComponent - init()');
    window.audioManager = this;
    this.audioEnabled = true;

    // Music:
    this.backgroundMusicElement = document.querySelector('#background-music');

    this.musicElement = document.querySelector('#musicElement');

    // Sounds:
    this.placeholderSoundElement = document.querySelector('#placeholder-audio');

    // Events:
    /* window.addEventListener('click', (event) => {
      // if (this.backgroundMusicElement.paused) this.playMusic();
    });
    window.addEventListener('blur', (event) => {
      // if (!this.backgroundMusicElement.paused) this.backgroundMusicElement.pause();
      this.musicElement.components.sound.pauseSound();
    });
    window.addEventListener('focus', (event) => {
      // if (this.backgroundMusicElement.paused) this.backgroundMusicElement.play();
      this.musicElement.components.sound.playSound();
    }); */
  },

  startGame() {
    if (!this.allSound) {
      const allEntities = document.querySelectorAll('a-entity');
      this.allSound = [];
      allEntities.forEach((entity) => {
        if (entity.components.sound) this.allSound.push(entity);
      });
      this.allSound.forEach((soundElement) => {
        if (!soundElement.components.sound.originalVolume) soundElement.components.sound.originalVolume = soundElement.components.sound.data.volume;
      });
    }
    this.setAudioEnabled(this.audioEnabled);
  },

  setAudioEnabled(newStatus = true) {
    this.audioEnabled = newStatus;
    this.backgroundMusicElement.muted = !newStatus;

    if (window.gameManager.hvoThreejs && window.gameManager.hvoThreejs.hvo) {
      window.gameManager.hvoThreejs.hvo.setAudioEnabled(newStatus);
    }
    if (this.allSound && this.allSound.length > 0) {
      this.allSound[0].components.sound.listener.setMasterVolume(newStatus ? 1 : 0);
    }
    if (window.gameManager.aScene && window.gameManager.aScene.audioListener) {
      window.gameManager.aScene.audioListener.setMasterVolume(newStatus ? 1 : 0);
    }
  },

  playSound() {},

  playMusic() {
    console.log('playMusic - this.backgroundMusicElement', this.backgroundMusicElement);
    try {
      // this.backgroundMusicElement.loop = true;
      // this.backgroundMusicElement.play();
      this.musicElement.components.sound.playSound();
    } catch (error) {
      console.error('audioManager playMusic() - error:', error);
    }
  },

  stopMusic() {
    gsap.to(this.backgroundMusicElement, {
      volume: 0,
      duration: Config.musicFadeOutDuration,
      ease: 'power4.in',
      onComplete: () => {
        this.backgroundMusicElement.pause();
      },
    });
  },
};

export { audioManagerComponent };
