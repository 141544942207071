import gsap from 'gsap';
import poiDataJson from '../../data/poi-data.js';
import Utils from './utils.js';
import AnalyticsManager from './analytics-manager.js';

const poiComponent = {
  schema: {
    poiDataKey: { // Key from JSON config file
      type: 'string',
      default: '',
    },
    poiTexture: {
      type: 'string',
      default: '',
    },
  },

  init() {
    this.debug = Utils.paramIsEnabled('debug');
    this.poiData = poiDataJson[this.data.poiDataKey];
    this.pieChartSoundElement = document.querySelector('#pieChartSoundElement');
    console.log('poiComponent init() - this.data:', this.data, '\nthis:', this, '\nthis.el.components', this.el.components);
    // this.reticleFocused = this.el.querySelector('.reticleFocused');

    // Texture
    this.textureElement = document.createElement('a-plane');
    this.textureElement.name = 'textureElement';
    this.textureElement.setAttribute('height', 0.45);
    this.textureElement.setAttribute('width', 0.45);
    this.textureElement.setAttribute('draw-on-top', {
      renderOrder: 1,
    });
    this.textureElement.setAttribute('src', "#poi-indicator-4");
    this.textureElement.setAttribute('material', {
      shader: 'flat',
      src: this.data.poiTexture,
      color: '#ffffff',
      transparent: true,
      alphaTest: 0.001,
    });
    this.el.appendChild(this.textureElement);

    this.shadowEl = this.el.sceneEl.querySelector(`.poiShadow_${this.data.poiDataKey}`);
    this.shadowEl.setAttribute('position', {
      x: this.el.object3D.position.x,
      y: 0.11,
      z: this.el.object3D.position.z,
    });

    this.el.addEventListener('fusing', this.pointFusing.bind(this));
    this.el.addEventListener('click', this.pointClicked.bind(this));
    this.el.addEventListener('mouseleave', this.pointLeave.bind(this));
    this.setEnabled(false);
    setTimeout(() => {
      // this.setHighlight(false, true);
      this.el.object3D.scale.set(0, 0, 0);
    }, 500);

    // Glow
    this.glowElement = document.createElement('a-plane');
    this.glowElement.name = 'glowElement';
    this.glowElement.setAttribute('height', 0.45);
    this.glowElement.setAttribute('width', 0.45);
    this.glowElement.setAttribute('material', {
      shader: 'flat',
      src: '#poi-glow',
      color: '#ffffff',
      transparent: true,
      depthTest: false,
      depthWrite: false,
      alphaTest: 0.01,
      opacity: 0,
    });
    this.glowElement.setAttribute('position', { x: 0, y: 0, z: -0.005 });
    this.glowElement.setAttribute('rotation', { x: 0, y: 0, z: 0 });
    this.glowElement.setAttribute('scale', { x: 1.09, y: 1.09, z: 1.09 });
    this.el.appendChild(this.glowElement);

    if (this.debug) {
      const debugCube = new THREE.Mesh(
        new THREE.BoxGeometry(0.25, 0.25, 0.25),
        new THREE.MeshBasicMaterial({ color: 0x00aaff, wireframe: true }),
      );
      this.el.object3D.add(debugCube);
    }
  },

  pointFusing() {
    // this.setHighlight(true);
    // Sound:
    this.playHoverSound();
    // Particles:
    if (this.poiData.hoverParticles) {
      if (!this.hoverParticlesElement) {
        this.hoverParticlesElement = document.querySelector(this.poiData.hoverParticles);
      }
      this.hoverParticlesElement.components['particle-system'].startParticles();
    }
  },

  pointClicked() {
    this.isClicked = true;
    window.gameManager.setAllPoisEnabled(false); // Hide all POI first
    window.gameManager.showHideWelcomeMessageTexture(false);
    // this.setGlowing(false);
    window.gameManager.disableAllPoisHighlight();
    window.gameManagerCurrentPoi = this;

    // Sound:
    this.playActiveSound();

    setTimeout(() => {
      window.cameraHudComponent.showHidePoiInfo(true, false, this.poiData);
      // this.setHighlight(false);
    }, this.poiData.delayAfterClick ? 1200 : 400);

    // AnalyticsManager.sendRoomSelectedEvent(this.data.poiDataKey);
    AnalyticsManager.sendRoomSelectedEvent(this.poiData.poiTitle);
  },

  closePoint() {
    this.isClicked = false;
    window.cameraHudComponent.showHidePoiInfo(false, false);
    window.gameManager.setAllPoisEnabled(true);
  },

  pointLeave() {
    if (this.isClicked) return;
    // this.setHighlight(false);
    this.stopHoverSound();
    if (this.poiData.hoverParticles) {
      if (!this.hoverParticlesElement) {
        this.hoverParticlesElement = document.querySelector(this.poiData.hoverParticles);
      }
      this.hoverParticlesElement.components['particle-system'].stopParticles();
    }
  },

  setHighlight(newStatus = true, instant = false) {
    const ripplesTweenDuration = 1500;
    //console.log('setHighlight - this.el:', this.el);
    gsap.killTweensOf(this.el.components.material.material); ///////
    gsap.to(this.el.components.material.material, {
      opacity: newStatus ? 0 : 1,
      duration: instant ? 0 : 0.4,
      ease: 'power2.out',
    });
    // console.log('setHighlight() - newStatus:', newStatus, ', material', this.el.components.material);
    if (this.alreadyAppeared) {
      this.el.setAttribute('animation__scale', {
        property: 'scale',
        to: `${newStatus ? 1.2 : 1} ${newStatus ? 1.2 : 1} ${newStatus ? 1.2 : 1}`,
        easing: newStatus ? 'easeOutBack' : 'easeOutSine',
        dur: 350,
      });
    }
    // this.reticleFocused.components.material.material.opacity = newStatus ? 1 : 0;
  },

  setGlowing(newStatus = true, instant = false) {
    if (this.glowingTwn) this.glowingTwn.kill();
    if (this.glowingScaleTwn) this.glowingScaleTwn.kill();
    if (newStatus) {
      this.glowingTwn = gsap.to(this.glowElement.components.material.material, {
        // opacity: 0.9,
        duration: 4.75,
        repeat: -1,
        keyframes: {
          '0%': { opacity: 0 },
          '16%': { opacity: 0.9 },
          '32%': { opacity: 0 },
          '100%': { opacity: 0 },
        },
        ease: 'sine.inOut',
      });
      this.glowingScaleTwn = gsap.to(this.textureElement.object3D.scale, {
        // x: 1.1,
        // y: 1.1,
        // z: 1.1,
        duration: 4.75,
        repeat: -1,
        keyframes: {
          '0%': { x: 1, y: 1, z: 1 },
          '16%': { x: 1.08, y: 1.08, z: 1.08 },
          '32%': { x: 1, y: 1, z: 1 },
          '100%': { x: 1, y: 1, z: 1 },
        },
        ease: 'sine.inOut',
      });
    }
    else {
      this.glowingTwn = gsap.to(this.glowElement.components.material.material, {
        opacity: 0,
        duration: 1.0,
        ease: 'sine.inOut',
      });
      this.glowingScaleTwn = gsap.to(this.textureElement.object3D.scale, {
        x: 1,
        y: 1,
        z: 1,
        duration: 1.0,
        ease: 'sine.inOut',
      });
    }
    /* if (this.alreadyAppeared) {
      this.el.setAttribute('animation__scale', {
        property: 'scale',
        to: `${newStatus ? 1.2 : 1} ${newStatus ? 1.2 : 1} ${newStatus ? 1.2 : 1}`,
        easing: newStatus ? 'easeOutBack' : 'easeOutSine',
        dur: 350,
      });
    } */
  },

  setEnabled(newStatus = true) {
    const twnEase = newStatus ? 'back.out' : 'back.in';
    gsap.to(this.el.object3D.scale,
      /* {
        x: newStatus ? 0 : 1,
        y: newStatus ? 0 : 1,
        z: newStatus ? 0 : 1,
      }, */
      {
        x: newStatus ? 1 : 0,
        y: newStatus ? 1 : 0,
        z: newStatus ? 1 : 0,
        duration: 0.3,
        ease: twnEase,
        onStart: () => {
          if (newStatus) {
            this.el.setAttribute('visible', true);
          }
          else {
            this.el.classList.remove('raycastInteractable');
          }
        },
        onComplete: () => {
          if (newStatus) {
            this.el.classList.add('raycastInteractable');
          }
          else {
            this.el.setAttribute('visible', false);
          }
        },
      },
    );
    gsap.to(this.shadowEl.object3D.scale,
      {
        x: newStatus ? 1 : 0.001,
        y: newStatus ? 1 : 0.001,
        z: newStatus ? 1 : 0.001,
        duration: 0.3,
        ease: twnEase,
      },
    );
  },

  appearTween(playSound = true) {
    this.alreadyAppeared = true;
    if (playSound) this.playAppearSound();
    gsap.fromTo(this.el.object3D.scale,
      {
        x: 0, y: 0, z: 0,
      },
      {
        x: 1,
        y: 1,
        z: 1,
        duration: 0.3,
        ease: 'back.out',
        onStart: () => {
          this.el.setAttribute('visible', true);
        },
        onComplete: () => {
          this.el.classList.add('raycastInteractable');
          // this.setGlowing(true);
        },
      },
    );
    gsap.to(this.shadowEl.object3D.scale,
      {
        x: 1,
        y: 1,
        z: 1,
        duration: 0.3,
        ease: 'back.out',
      },
    );
  },

  // Sound >>>
  playHoverSound() {
    this.orbHoverSoundElement.components.sound.pool.children[0].setVolume(this.orbHoverSoundElement.originalVolume);
    this.orbHoverSoundElement.components.sound.stopSound();
    this.orbHoverSoundElement.components.sound.playSound();

    if (this.hoverSoundElement) {
      if (this.hoverSoundFadeTwn) this.hoverSoundFadeTwn.kill();
      this.hoverSoundElement.components.sound.pool.children[0].setVolume(1);
      this.hoverSoundElement.components.sound.stopSound();
      this.hoverSoundElement.components.sound.playSound();
    }
  },

  playAppearSound() {
    this.orbAppearSoundElement.components.sound.pool.children[0].setVolume(this.orbAppearSoundElement.originalVolume);
    this.orbAppearSoundElement.components.sound.stopSound();
    this.orbAppearSoundElement.components.sound.playSound();
  },

  stopHoverSound() {
    if (this.hoverSoundElement) {
      // TODO Fade volume first
      const t = { value: this.hoverSoundElement.components.sound.data.volume };
      this.hoverSoundFadeTwn = gsap.to(t, {
        value: 0,
        duration: 0.5,
        ease: 'power1.out',
        onUpdate: () => {
          this.hoverSoundElement.components.sound.pool.children[0].setVolume(t.value);
        },
        onComplete: () => {
          this.hoverSoundElement.components.sound.stopSound();
        },
      });
    }
  },

  playActiveSound() {
    /* if (this.activeSoundElement) {
      if (this.activeSoundFadeTwn) this.activeSoundFadeTwn.kill();
      this.activeSoundElement.components.sound.pool.children[0].setVolume(1);
      this.activeSoundElement.components.sound.stopSound();
      this.activeSoundElement.components.sound.playSound();
    } */
    window.splashComponentRef.playButtonSound();
  },

  stopActiveSound() {
    if (this.activeSoundElement) {
      // TODO Fade volume first
      const t = { value: this.activeSoundElement.components.sound.data.volume };
      this.activeSoundFadeTwn = gsap.to(t, {
        value: 0,
        duration: 0.5,
        ease: 'power1.out',
        onUpdate: () => {
          this.activeSoundElement.components.sound.pool.children[0].setVolume(t.value);
        },
        onComplete: () => {
          this.activeSoundElement.components.sound.stopSound();
        },
      });
    }
  },
  // <<< Sound
};

export { poiComponent };
