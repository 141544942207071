/* eslint-disable no-lonely-if */
import gsap from 'gsap';
import { NodeToyMaterial } from '@nodetoy/three-nodetoy';
import Config from './config.js';
import Utils from './utils.js';
import { data as causticShaderData } from './causticShaderData.js';
import { data as jellyfishShaderData } from './jellyfishShaderData_noLines.js';
import AnalyticsManager from './analytics-manager.js';
import glowTexture from '../../assets/textures/glow.png';

const gameManagerComponent = {
  init() {
    if (window.preventAppInit) return;
    this.textureLoader = new THREE.TextureLoader();
    window.gameManager = this;
    this.jellyfishWallVideos = [];
    this.oceanWallVideos = [];
    this.mountainWallVideos = [];
    this.flightWallVideos = [];
    this.flightKioskWallVideos = [];
    this.aScene = document.querySelector('a-scene');
    this.aScene.object3D.name = 'aScene';
    this.aCamera = document.getElementById('camera');
    this.floorPlane = document.getElementById('floorPlane');
    if (this.floorPlane && this.floorPlane.components['gltf-model'] && this.floorPlane.components['gltf-model'].model) {
      // Model was loaded before this init()
      console.log('floorPlane was loaded before this init()');
      this.floorPlaneFinishedLoading();
    } else {
      this.floorPlane.addEventListener('model-loaded', () => {
        this.floorPlaneFinishedLoading();
      });
    }

    this.rotationParent = this.aScene.querySelector('#rotationParent');
    this.originMarker = document.getElementById('originMarker');

    this.sceneOrigin = document.getElementById('sceneOrigin');
    this.sceneOrigin.object3D.name = 'sceneOrigin';
    this.sceneOrigin.object3D.originalScale = this.sceneOrigin.getAttribute('finalScale');
    this.sceneOrigin.object3D.scale.set(0.00001, 0.00001, 0.00001);
    this.sceneOrigin.object3D.visible = true;

    this.hiderPlane = document.getElementById('hiderPlane');
    this.welcomeMessageTexture = document.getElementById('welcomeMessageTexture');
    this.selectAnIcon = document.getElementById('selectAnIcon');

    this.objectModel = document.getElementById('objectModel');
    this.objectModel.object3D.name = 'objectModel';
    this.planetsModel = document.getElementById('planetsModel');
    this.charactersModel = document.getElementById('charactersModel');

    this.reflectionSphere = document.querySelector('#reflectionSphere');
    this.topDarkOverlay = document.querySelector('#top_dark_overlay');

    // Videos:
    this.jellyfishWallVideo = document.getElementById('jellyfishWallVideo');
    this.oceanWallVideo = document.getElementById('oceanWallVideo');
    this.mountainWallVideo = document.getElementById('mountainWallVideo');
    this.flightWallVideo = document.getElementById('flightWallVideo');
    this.flightKioskWallVideo = document.getElementById('flightKioskWallVideo');

    // Lights:
    this.hemisphereLight = document.getElementById('hemisphereLight');
    this.lightRoom1 = document.getElementById('light_room_1');
    this.lightRoom2 = document.getElementById('light_room_2');
    this.lightRoom3 = document.getElementById('light_room_3');
    this.lightRoom4 = document.getElementById('light_room_4');
    this.lightRoom5 = document.getElementById('light_room_5');

    this.pois = document.querySelectorAll('.poiComponent');

    if (this.objectModel && this.objectModel.components['gltf-model'] && this.objectModel.components['gltf-model'].model) {
      // Model was loaded before this init()
      console.log('Model was loaded before this init()');
      this.objectModelFinishedLoading();
    } else {
      this.objectModel.addEventListener('model-loaded', () => {
        this.objectModelFinishedLoading();
      });
    }

    if (this.planetsModel && this.planetsModel.components['gltf-model'] && this.planetsModel.components['gltf-model'].model) {
      // Model was loaded before this init()
      console.log('Model was loaded before this init()');
      this.planetsModelFinishedLoading();
    } else {
      this.planetsModel.addEventListener('model-loaded', () => {
        this.planetsModelFinishedLoading();
      });
    }

    if (this.charactersModel && this.charactersModel.components['gltf-model'] && this.charactersModel.components['gltf-model'].model) {
      // Model was loaded before this init()
      console.log('Model was loaded before this init()');
      this.charactersModelFinishedLoading();
    } else {
      this.charactersModel.addEventListener('model-loaded', () => {
        this.charactersModelFinishedLoading();
      });
    }

    this.el.sceneEl.renderer.sortObjects = true;
  },

  customInit() {
    /* if (!window.preventAppInit) {
    } */
  },

  startGame() {
    console.log('gameManager startGame()');
    setTimeout(() => {
      const welcomeScreenEnabled = false;
      if (welcomeScreenEnabled) { // TODO welcome screen
        window.cameraHudComponent.showHideWelcomeScreen(true); // Show welcome screen
      }
      else {
        this.welcomeScreenBtnClicked();
      }
    }, 1500);
    AnalyticsManager.sendOnboardingCompletedEvent();
  },

  welcomeScreenBtnClicked() {
    // this.startAllVideos();
    ///
    this.welcomeScreenCompleted = true;
    //AnalyticsManager.sendOnboardingCompletedEvent();
    window.cameraHudComponent.showHideWelcomeScreen(false);
    window.cameraHudComponent.showHideTrackingPopupContainer(true);
    window.cameraHudComponent.startTrackingPopupAutocloseProgress(5, () => {
      window.cameraHudComponent.activateWithoutCardClicked();
    });
    // window.splashComponentRef.playButtonSound();
    if (this.imageTargetFound) {
      // If already detecting Image Targer, spawn the model now that welcomeScreenCompleted is true
      this.xrimagefound();
    }

    if (window.isDesktop) {
      setTimeout(() => {
        this.placeScene();
      }, 500);
    }
  },

  startAllVideos() {
    console.log('startAllVideos()');
    // this.jellyfishWallVideo.getAttribute('material').src.play();
    this.oceanWallVideo.getAttribute('material').src.play();
    this.mountainWallVideo.getAttribute('material').src.play();
    this.flightWallVideo.getAttribute('material').src.play();
    this.flightKioskWallVideo.getAttribute('material').src.play();
  },

  xrimagefound(e) {
    this.imageTargetFound = true;
    console.log('xrimagefound - e:', e);
    if (this.showTrackingMessageTimeout) clearTimeout(this.showTrackingMessageTimeout); // Stop setTimeout
    if (this.welcomeScreenCompleted && !this.activatedNoCardMode) {
      if (!this.scenePlaced) {
        // window.cameraHudComponent.showHidePlaceSceneContainer(true);
        // window.cameraHudComponent.showHideTrackingPopupContainer(false);
        this.placeScene(); // Place scene automatically when the Image Target is detected
        AnalyticsManager.sendActivateARYesCardEvent();
      } else {
        window.cameraHudComponent.showHidePlaceSceneContainer(false);
        window.cameraHudComponent.showHideTrackingPopupContainer(false);
        window.cameraHudComponent.showHideUtilButtons(true);
      }
    }
  },

  xrimagelost(e) {
    this.imageTargetFound = false;
    console.log('xrimagelost - e.detail:', e.detail);
    if (this.welcomeScreenCompleted && !this.activatedNoCardMode) {
      if (this.showTrackingMessageTimeout) clearTimeout(this.showTrackingMessageTimeout); // Stop setTimeout
      this.showTrackingMessageTimeout = setTimeout(() => {
        window.cameraHudComponent.showHidePlaceSceneContainer(false);
        window.cameraHudComponent.showHideTrackingPopupContainer(true);
        window.cameraHudComponent.showHideUtilButtons(false);
      }, 1500);
    }
  },

  objectModelFinishedLoading() {
    console.log('objectModelFinishedLoading()');
    // Adjust model:
    this.objectModel.object3D.traverse((node) => {
      // Hide floor
      if (node.name.toLowerCase().indexOf('np_armailer_floorplandesign') > -1) {
        node.visible = false;
      }
      // Set video materials
      if (node.name.toLowerCase().indexOf('jellyfish_wall') > -1) {
        this.jellyfishWallVideos.push(node);
      } else if (node.name.toLowerCase().indexOf('ocean_wall') > -1) {
        this.oceanWallVideos.push(node);
      } else if (node.name.toLowerCase().indexOf('mountain_wall') > -1) {
        this.mountainWallVideos.push(node);
      } else if (node.name.toLowerCase().indexOf('flight_wall_24fps_1') > -1) {
        this.flightWallVideos.push(node);
      } else if (node.name.toLowerCase().indexOf('flight-room_kiosk_animation') > -1) {
        this.flightKioskWallVideos.push(node);
      }
      // Planets:
      else if (node.name.toLowerCase().indexOf('star_planet_parent') > -1) {
        this.starPlanetParent = node;
      }
      // Update materials:
      else if (node.name.toLowerCase().indexOf('floor_caustic_effect') > -1) {
        this.floorCausticEffect = node;
        node.material = new NodeToyMaterial({
          data: causticShaderData,
        });
        node.material.uniforms.CausticColor1.value.x = 0;
        node.material.uniforms.CausticColor1.value.y = 0.45;
        node.material.uniforms.CausticColor1.value.z = 1;
        node.material.uniforms.CausticColor2.value.x = 0;
        node.material.uniforms.CausticColor2.value.y = 0.5;
        node.material.uniforms.CausticColor2.value.z = 1;
        node.material.uniforms.CausticsOpacity.value = 0; // Tween this to appear
        node.material.uniforms.CausticsMultiply.value = 0.9;
        node.material.uniforms.CausticsOpacityPower.value = 1.3;
        node.material.uniforms.CausticsPower.value = 1.9;
        node.material.uniforms.CausticsSpeed1.value.x = 0.7;
        node.material.uniforms.CausticsSpeed1.value.y = 0.5;
        node.material.uniforms.CausticsSpeed2.value.x = -0.4;
        node.material.uniforms.CausticsSpeed2.value.y = -0.3;
        node.material.uniforms.CausticsTiling.value.x = 10;
        node.material.uniforms.CausticsTiling.value.y = 10;
        // console.log('this.objectModel.object3D.traverse - node.material floor_caustic_effect:', node.material);
      }
      else if (node.name.toLowerCase().indexOf('cube024_1') > -1 && !node.material.alreadyAdjustedMaterial) {
        this.proyectorObj = node;
        node.material.alreadyAdjustedMaterial = true;
        // node.material.emissive.setHex(0x479eff);
        node.material.emissive.setHex(0xffffff);
        node.material.emissiveMap = node.material.map;
        node.material.opacity = 0;
      }
      else if (node.name.toLowerCase().indexOf('gloster_style_bi_plane001') > -1 && !node.alreadyAdjusted) {
        node.alreadyAdjusted = true;
        node.position.x = -0.015;
      }
      // Apply envMap
      else if (node.material && !node.material.alreadyAdjustedMaterial) {
        node.material.alreadyAdjustedMaterial = true;
        // console.log('node with mat:', node.name, node.material, node.material.metalness, 'sum', node.material.metalness + 0.15, 'res', Math.min(node.material.metalness + 0.15, 1));
        node.material.envMap = this.reflectionSphere.object3D.children[0].material.envMap;
        // node.material.roughness *= 0.5;
        // node.material.metalness = Math.min(node.material.metalness + 0.1, 1);
      }
    });

    this.starPlanetParent.add(this.planetsModel.object3D);
    this.objectModel.object3D.add(this.charactersModel.object3D);
  },

  floorPlaneFinishedLoading() {
    this.floorPlane.object3D.traverse((node) => {
      if (node.material && node.material.color) {
        node.material.color.r *= 0.7;
        node.material.color.g *= 0.7;
        node.material.color.b *= 0.7;
      }
    });
    this.floorPlane.setAttribute('animation-mixer', {
      clip: this.floorPlane.components['gltf-model'].model.animations[0].name,
      loop: 'once',
      clampWhenFinished: true,
    });
    setTimeout(() => {
      // Pause animation in the first frame
      this.floorPlane.setAttribute('animation-mixer', {
        timeScale: 0,
      });
    }, 1);
  },

  planetsModelFinishedLoading() {
    this.planetsModel.object3D.traverse((node) => {
      if (node.material && node.material.color) {
        if (node.name.toLowerCase().indexOf('_wireframe') > -1) {
          node.material.wireframe = true;
          // console.log('this.planetsModel.object3D.traverse _wireframe - node:', node);
          // Add sprite
          const glowMap = this.textureLoader.load(glowTexture);
          const spriteMaterial = new THREE.SpriteMaterial({
            map: glowMap,
            color: 0x00d5ff,
            depthWrite: false,
            opacity: 0.85,
          });
          // console.log('this.planetsModel.object3D.traverse - spriteMaterial:', spriteMaterial);
          const sprite = new THREE.Sprite(spriteMaterial);
          sprite.renderOrder = -1;
          // console.log('this.planetsModel.object3D.traverse - sprite:', sprite);
          if (node.name.toLowerCase().indexOf('mars') > -1) {
            node.material.color.setHex(0x000000);
            node.material.emissive.setHex(0xE28FA7);
            sprite.scale.set(0.6, 0.6, 0.6);
            spriteMaterial.color.setHex(0xEDBBC9);
          }
          else if (node.name.toLowerCase().indexOf('mercury') > -1) {
            node.material.color.setHex(0x000000);
            node.material.emissive.setHex(0x95C8E4);
            sprite.scale.set(13, 13, 13);
            spriteMaterial.color.setHex(0xCAE0EC);
          }
          else if (node.name.toLowerCase().indexOf('venus') > -1) {
            node.material.color.setHex(0x000000);
            node.material.emissive.setHex(0xF3CA9B);
            sprite.scale.set(0.7, 0.7, 0.7);
            spriteMaterial.color.setHex(0xFFE9D0);
          }
          node.add(sprite);
        }
        else if (node.name === 'circle002_1' || node.name === 'Sphere001' || node.name === 'circle001_1') {
          node.material.emissiveMap = node.material.map;
          node.material.emissive.setHex(0xffffff);
        }
        else if (node.name === 'circle002') {
          node.material.emissive.setHex(0xffffff);
        }
        else if (node.name === 'Star_Sphear') {
          node.material.emissive.setHex(0xffffff);
        }
      }
    });
    this.planetsModel.setAttribute('position', {
      x: 0,
      y: 0.9,
      z: 0,
    });
  },

  charactersModelFinishedLoading() {
    this.charactersModel.object3D.roomParents = [];
    this.charactersModel.object3D.children[0].children.forEach((node) => {
      if (node.name.toLowerCase().indexOf('room_') > -1) {
        node.position.y -= 0.5;
        this.charactersModel.object3D.roomParents.push(node);
      }
    });
    this.charactersModel.setAttribute('animation-mixer', {
      clip: this.charactersModel.components['gltf-model'].model.animations[0].name,
      timeScale: 1,
      loop: 'repeat',
    });
  },

  enableJellyfishWallVideo() {
    this.jellyfishWallVideos.forEach((node) => {
      node.material = new NodeToyMaterial({
        data: jellyfishShaderData,
      });
      node.material.uniforms.MainTexture.value = this.jellyfishWallVideo.components.material.material.map;
      node.material.uniforms.MainTexture.value.flipY = false;
      node.material.uniforms.wobbleAmplitude.value = 0.3;
      node.material.uniforms.wobbleFrequency.value = 6;
      node.material.uniforms.wobbleSpeed.value = 1.5;
      node.material.side = 2;
      node.renderOrder = 1;
    });
  },

  enableOceanWallVideo() {
    this.oceanWallVideos.forEach((node) => {
      node.material = this.oceanWallVideo.object3D.children[0].material;
      node.material.roughness = 1;
      node.material.metalness = 0;
      node.material.envMap = null;
      node.material.map.flipY = false;
    });
  },

  enableMountainWallVideo() {
    this.mountainWallVideos.forEach((node) => {
      node.material = this.mountainWallVideo.object3D.children[0].material;
      node.material.roughness = 1;
      node.material.metalness = 0;
      node.material.envMap = null;
      node.material.map.flipY = false;
    });
  },

  enableFlightWallVideo() {
    this.flightWallVideos.forEach((node) => {
      node.material = this.flightWallVideo.object3D.children[0].material;
      node.material.roughness = 1;
      node.material.metalness = 0;
      node.material.envMap = null;
      node.material.map.flipY = false;
    });
  },

  enableFlightKioskWallVideo() {
    this.flightKioskWallVideos.forEach((node) => {
      node.material = this.flightKioskWallVideo.object3D.children[0].material;
      node.material.roughness = 1;
      node.material.metalness = 0;
      node.material.envMap = null;
      node.material.map.flipY = false;
      node.material.side = 2;
      node.position.y += 0.002;
    });
  },

  tick() {
    NodeToyMaterial.tick();
  },

  placeScene() {
    if (this.scenePlaced) return;
    console.log('gameManager placeScene()');
    this.scenePlaced = true;
    window.cameraHudComponent.showHidePlaceSceneContainer(false);
    window.cameraHudComponent.showHideTrackingPopupContainer(false);
    gsap.to(this.floorPlane.object3D.scale, {
      x: this.sceneOrigin.object3D.originalScale,
      y: this.sceneOrigin.object3D.originalScale,
      z: this.sceneOrigin.object3D.originalScale,
      duration: 0.4,
      ease: 'back.out',
      onStart: () => {
        this.floorPlane.setAttribute('visible', true);
      },
      onComplete: () => {
        this.floorPlane.setAttribute('animation-mixer', {
          timeScale: 1.2,
        });
      },
    });
    // Show museum
    setTimeout(() => {
      this.showHideWelcomeMessageTexture(true);
    }, 2700);
    // TODO: Do this when the envelope unfold animation ends instead of after a timer?
    setTimeout(() => {
      gsap.to(this.sceneOrigin.object3D.scale, { // Appear scene model
        x: this.sceneOrigin.object3D.originalScale,
        y: this.sceneOrigin.object3D.originalScale,
        z: this.sceneOrigin.object3D.originalScale,
        duration: 0.2,
        ease: 'back.out',
        onStart: () => {
          this.sceneOrigin.object3D.visible = true;
          // Start animation
          this.objectModel.setAttribute('animation-mixer', {
            clip: this.objectModel.components['gltf-model'].model.animations[0].name,
            timeScale: 1.33,
            // loop: 'repeat',
            loop: 'once',
            clampWhenFinished: true,
          });
          this.planetsModel.setAttribute('animation-mixer', {
            clip: this.planetsModel.components['gltf-model'].model.animations[0].name,
            timeScale: 1,
            loop: 'repeat',
          });
        },
        onComplete: () => {
          AnalyticsManager.sendViewMuseumEvent();
          this.enableJellyfishWallVideo(); // V2. Enable all videos at the same time
          this.enableOceanWallVideo(); // V2. Enable all videos at the same time
          this.enableMountainWallVideo(); // V2. Enable all videos at the same time
          this.enableFlightWallVideo(); // V2. Enable all videos at the same time
          this.enableFlightKioskWallVideo(); // V2. Enable all videos at the same time
          setTimeout(() => {
            this.initPois();
            setTimeout(() => {
              this.enableAllPoisHighlight();
            }, 7500);
          }, 5000);
        },
      });
    }, 2100);
  },

  showHideWelcomeMessageTexture(newValue) {
    const showTwnDuration = 2.9;
    const hideTwnDuration = 1.9;

    if (this.welcomeMessageAlphaTwn) this.welcomeMessageAlphaTwn.kill();
    if (this.selectAnIconAlphaTwn) this.selectAnIconAlphaTwn.kill();
    if (this.topDarkOverlayAlphaTwn) this.topDarkOverlayAlphaTwn.kill();
    // if (this.welcomeMessageScaleTwn) this.welcomeMessageScaleTwn.kill();
    if (this.welcomeMessagePositionTwn) this.welcomeMessagePositionTwn.kill();

    this.welcomeMessageAlphaTwn = gsap.to(this.welcomeMessageTexture.components.material.material, {
      opacity: newValue ? 1 : 0,
      duration: newValue ? showTwnDuration : hideTwnDuration,
      ease: newValue ? 'power4.out' : 'power4.in',
    });
    this.selectAnIconAlphaTwn = gsap.to(this.selectAnIcon.components.material.material, {
      opacity: newValue ? 1 : 0,
      duration: newValue ? showTwnDuration : hideTwnDuration,
      delay: newValue ? 5.8 : 0,
      ease: newValue ? 'power4.out' : 'power4.in',
    });
    this.topDarkOverlayAlphaTwn = gsap.to(this.topDarkOverlay.components.material.material, {
      opacity: newValue ? 0.6 : 0,
      duration: newValue ? showTwnDuration : hideTwnDuration,
      ease: newValue ? 'power4.out' : 'power4.in',
      onStart: () => {
        if (newValue) this.topDarkOverlay.object3D.visible = true;
      },
      onComplete: () => {
        if (!newValue) this.topDarkOverlay.object3D.visible = false;
      },
    });
    if (newValue) { // Show
      /* this.welcomeMessageScaleTwn = gsap.from(this.welcomeMessageTexture.object3D.scale, {
        x: 0.001,
        y: 0.001,
        z: 0.001,
        duration: 1.0,
        ease: 'power3.out',
        onStart: () => {
          this.welcomeMessageTexture.object3D.visible = true;
        },
      }); */
      if (this.activatedNoCardMode || window.isDesktop) {
        this.welcomeMessagePositionTwn = gsap.from(this.welcomeMessageTexture.object3D.position, {
          y: this.welcomeMessageTexture.object3D.position.y - 0.09,
          duration: showTwnDuration,
          ease: 'power4.out',
        });
      }
      else {
        this.welcomeMessagePositionTwn = gsap.from(this.welcomeMessageTexture.object3D.position, {
          z: this.welcomeMessageTexture.object3D.position.z - 0.09,
          duration: showTwnDuration,
          ease: 'power4.out',
        });
      }
    }
    else { // Hide
      /* this.welcomeMessageScaleTwn = gsap.to(this.welcomeMessageTexture.object3D.scale, {
        x: 0.2,
        y: 0.2,
        z: 0.2,
        duration: 1.0,
        ease: 'power2.in',
        onComplete: () => {
          this.welcomeMessageTexture.object3D.visible = false;
        },
      }); */
      if (this.activatedNoCardMode || window.isDesktop) {
        this.welcomeMessagePositionTwn = gsap.to(this.welcomeMessageTexture.object3D.position, {
          y: this.welcomeMessageTexture.object3D.position.y - 0.07,
          duration: hideTwnDuration,
          ease: 'power4.in',
        });
      }
      else {
        this.welcomeMessagePositionTwn = gsap.to(this.welcomeMessageTexture.object3D.position, {
          z: this.welcomeMessageTexture.object3D.position.z - 0.07,
          duration: hideTwnDuration,
          ease: 'power4.in',
        });
      }
    }
  },

  startWithoutCardMode() {
    this.activatedNoCardMode = true;
    XR8.XrController.configure({
      imageTargets: [], // Remove Image Target
    });
    this.aScene.emit('recenter');
    this.originMarker.setAttribute('tap-place-cursor', true);
    gsap.from(this.originMarker.object3D.scale, {
      x: 0.001,
      y: 0.001,
      z: 0.001,
      duration: 0.5,
      ease: 'back.out',
      onStart: () => {
        setTimeout(() => {
          this.originMarker.setAttribute('visible', true);
        }, 50);
      },
    });
    window.tapPlaceCursorRef.startGame();

    //
    this.rotationParent.setAttribute('scale', { x: 0.00001, y: 0.00001, z: 0.00001 });

    this.rotationParent.object3D.add(this.sceneOrigin.object3D);
    this.sceneOrigin.setAttribute('position', { x: 0, y: 0, z: 0 });
    this.sceneOrigin.setAttribute('rotation', { x: 0, y: 0, z: 0 });
    console.log('this.sceneOrigin.getAttribute(rotation):', this.sceneOrigin.getAttribute('rotation'));

    this.rotationParent.object3D.add(this.hiderPlane.object3D);
    this.hiderPlane.setAttribute('position', { x: 0, y: -0.05, z: 0 });
    this.hiderPlane.setAttribute('rotation', { x: 270, y: 0, z: 0 });

    this.rotationParent.object3D.add(this.welcomeMessageTexture.object3D);
    this.welcomeMessageTexture.setAttribute('position', { x: 0, y: 0.97, z: 0 });
    this.welcomeMessageTexture.setAttribute('rotation', { x: 0, y: 0, z: 0 });

    this.rotationParent.object3D.add(this.floorPlane.object3D);
    this.floorPlane.setAttribute('position', {
      x: this.sceneOrigin.object3D.position.x,
      y: 0,
      z: this.sceneOrigin.object3D.position.z,
    });
    this.floorPlane.setAttribute('rotation', {
      x: 180,
      y: 90,
      z: 180,
    });
  },

  placeSceneWithoutCard() {
    // this.sceneOrigin.object3D.originalScale = 0.4;
    window.cameraHudComponent.showHideTrackingPopupContainer(false);
    this.placeScene();
    setTimeout(() => {
      this.rotationParent.setAttribute('position', {
        x: this.originMarker.object3D.position.x,
        y: 0,
        z: this.originMarker.object3D.position.z,
      });
      this.rotationParent.setAttribute('rotation', {
        x: 0,
        y: this.originMarker.object3D.rotation.y * (180 / Math.PI),
        z: 0,
      });

      this.rotationParent.setAttribute('xrextras-one-finger-rotate', { factor: 4 });
      this.rotationParent.setAttribute('scale', { x: 0.6, y: 0.6, z: 0.6 });
    }, 1);
  },

  adjustDesktopHierarchy() {
    this.aScene.object3D.attach(this.sceneOrigin.object3D);
    this.sceneOrigin.setAttribute('position', { x: 0, y: 0, z: -1 });
    this.sceneOrigin.setAttribute('rotation', { x: 0, y: 0, z: 0 });

    this.aScene.object3D.attach(this.floorPlane.object3D);
    this.floorPlane.setAttribute('position', { x: 0, y: 0.001, z: -1 });
    this.floorPlane.setAttribute('rotation', { x: 180, y: 90, z: 180 });

    this.aScene.object3D.attach(this.welcomeMessageTexture.object3D);
    this.welcomeMessageTexture.setAttribute('position', { x: 0, y: 0.97, z: -1 });
    this.welcomeMessageTexture.setAttribute('rotation', { x: 0, y: 0, z: 0 });
  },

  initPois() {
    this.poisAlreadyInited = true;
    //console.log('initPois - this.pois:', this.pois);

    gsap.to(this.hemisphereLight.components.light.light, {
      intensity: 0.2,
      duration: 1.4,
      ease: 'power2.inOut',
      onComplete: () => {
        this.hemisphereLight.components.light.attrValue.intensity = 0.2;
        this.hemisphereLight.components.light.data.intensity = 0.2;
        this.hemisphereLight.components.light.updateProperties();
      },
    });

    for (let i = 0; i < this.pois.length; i++) {
      setTimeout(() => {
        const playSound = false;
        this.pois[i].components['point-of-interest'].appearTween(playSound);
        // Appear characters in room:
        gsap.to(this.charactersModel.object3D.roomParents[i].position, {
          y: 0,
          duration: 2,
          ease: 'power2.out',
        });
        // Enable room effects:
        switch (i) {
          case 0: // Jellyfish Room
            // this.enableJellyfishWallVideo(); // V1. Enable videos one by one

            gsap.to(this.lightRoom1.components.light.light, {
              intensity: this.lightRoom1.getAttribute('originalIntensity'),
              duration: 1,
              ease: 'power2.inOut',
              onComplete: () => {
                this.lightRoom1.components.light.attrValue.intensity = this.lightRoom1.getAttribute('originalIntensity');
                this.lightRoom1.components.light.data.intensity = this.lightRoom1.getAttribute('originalIntensity');
                this.lightRoom1.components.light.updateProperties();
              },
            });
            break;
          case 1: // Ocean Room
            // this.enableOceanWallVideo(); // V1. Enable videos one by one
            gsap.to(this.floorCausticEffect.material.uniforms.CausticsOpacity, {
              value: 1,
              duration: 1,
              ease: 'power2.inOut',
            });
            gsap.to(this.proyectorObj.material, {
              opacity: 1,
              duration: 1,
              ease: 'power2.inOut',
            });

            gsap.to(this.lightRoom2.components.light.light, {
              intensity: this.lightRoom2.getAttribute('originalIntensity'),
              duration: 1,
              ease: 'power2.inOut',
              onComplete: () => {
                this.lightRoom2.components.light.attrValue.intensity = this.lightRoom2.getAttribute('originalIntensity');
                this.lightRoom2.components.light.data.intensity = this.lightRoom2.getAttribute('originalIntensity');
                this.lightRoom2.components.light.updateProperties();
              },
            });
            break;
          case 2: // Mountain Room
            // this.enableMountainWallVideo(); // V1. Enable videos one by one

            gsap.to(this.lightRoom3.components.light.light, {
              intensity: this.lightRoom3.getAttribute('originalIntensity'),
              duration: 1,
              ease: 'power2.inOut',
              onComplete: () => {
                this.lightRoom3.components.light.attrValue.intensity = this.lightRoom3.getAttribute('originalIntensity');
                this.lightRoom3.components.light.data.intensity = this.lightRoom3.getAttribute('originalIntensity');
                this.lightRoom3.components.light.updateProperties();
              },
            });
            break;
          case 3: // Flight Room
            // this.enableFlightWallVideo(); // V1. Enable videos one by one
            // this.enableFlightKioskWallVideo(); // V1. Enable videos one by one

            gsap.to(this.lightRoom4.components.light.light, {
              intensity: this.lightRoom4.getAttribute('originalIntensity'),
              duration: 1,
              ease: 'power2.inOut',
              onComplete: () => {
                this.lightRoom4.components.light.attrValue.intensity = this.lightRoom4.getAttribute('originalIntensity');
                this.lightRoom4.components.light.data.intensity = this.lightRoom4.getAttribute('originalIntensity');
                this.lightRoom4.components.light.updateProperties();
              },
            });
            break;
          case 4: // VR Room
            // window.cameraHudComponent.showHideTopHeader(true);
            window.cameraHudComponent.showHideUtilButtons(true);

            gsap.to(this.lightRoom5.components.light.light, {
              intensity: this.lightRoom5.getAttribute('originalIntensity'),
              duration: 1,
              ease: 'power2.inOut',
              onComplete: () => {
                this.lightRoom5.components.light.attrValue.intensity = this.lightRoom5.getAttribute('originalIntensity');
                this.lightRoom5.components.light.data.intensity = this.lightRoom5.getAttribute('originalIntensity');
                this.lightRoom5.components.light.updateProperties();
              },
            });
            break;
          default:
            break;
        }
      }, 1600 + (900 * i));
    }
  },

  enableAllPoisHighlight() {
    if (this.poisHighlightDisabled) return;
    for (let i = 0; i < this.pois.length; i++) {
      setTimeout(() => {
        if (!this.poisHighlightDisabled) this.pois[i].components['point-of-interest'].setGlowing(true);
      }, 220 * i);
    }
  },

  disableAllPoisHighlight() {
    this.poisHighlightDisabled = true;
    for (let i = 0; i < this.pois.length; i++) {
      this.pois[i].components['point-of-interest'].setGlowing(false);
    }
  },

  setAllPoisEnabled(newValue = true) {
    for (let i = 0; i < this.pois.length; i++) {
      if (this.pois[i].components['point-of-interest'].alreadyAppeared) {
        this.pois[i].components['point-of-interest'].setEnabled(newValue);
      }
    }
    // window.cameraHudComponent.showHideTopHeader(newValue);
  },

  refreshExperience() {
    console.log('gameManager refreshExperience()');
  },
};

export { gameManagerComponent };
