/* globals XR8 XRExtras THREE TWEEN */
import gsap from 'gsap';
import Utils from './utils.js';
import loadingFooterImage from '../../assets/ui-images/powered_by_8th_wall.svg';
// import handcraftedImage from '../../assets/ui-images/handcraftedx2.png';
import loaderOverlayImg from '../../assets/ui-images/loader_overlay_min.png';
import waterImage from '../../assets/ui-images/loader_image_coral.png';
import boatImage from '../../assets/ui-images/loader_image_boat.png';
import mountainImage from '../../assets/ui-images/loader_image_mountain.png';
import airplaneImage from '../../assets/ui-images/loader_image_plane.png';
import spaceImage from '../../assets/ui-images/loader_image_space.png';
import permissionsHeaderImage from '../../assets/ui-images/permissions_top_header_min.png';
import userPromptErrorImage from '../../assets/ui-images/permissions-icon.png';
import AnalyticsManager from './analytics-manager.js';

let mainMenuContainer;
let loadImageContainer;
let loadBackground;
let requestingCameraPermissions;
let cameraSelectionWorldTrackingError;
let cameraPermissionsErrorApple;
let cameraPermissionsErrorAppleMessage;
let desktopNotSupportedContainer;
let browserNotSupportedContainer;
let unsupportedTrySafary;
let unsupportedTryChrome;
let allowPermissionsParent;
let permissionsDenied;
let refreshPageBtn;
let loadingContainer;
let denyPermissionsButton;
let allowPermissionsButton;
let startGameButton;
let rotateDevice;
let topLogo;
let buttonSoundElement;
let vh;
let vw;

const splashComponent = {
  init() {
    window.splashComponentRef = this;
    this.splashDOM = document.getElementById('splashscreen');
    this.cameraHud = document.getElementById('cameraHud');
    this.splashAllowText = document.getElementById('landing-allow-text');
    //this.start = document.getElementById('startBtn');
    this.landingOr = document.getElementById('landing-or');
    this.landingQrCode = document.getElementById('landing-qr-code');
    this.landingQrCodeText = document.getElementById('landing-qr-code-text');

    //this.start.onclick = addXRWeb; /////////

    window.XRExtras ? this.load() : window.addEventListener('xrextrasloaded', this.load);

    window.onresize = () => {
      this.resize();
    };
    this.resize();
  },

  customInit() {
    this.getElements();
    this.customizeLoadingPage();
    this.resize();
    this.checkMobileOrDesktop();
    window.gameManager.customInit();
    setTimeout(() => {
      document.body.style.visibility = 'visible';
    }, 10);
  },

  addXRWeb() {
    console.log('addXRWeb()');
    let scaleMode = 'responsive';
    if (Utils.paramIsEnabled('enableAbsoluteScale')) scaleMode = 'absolute';

    this.el.sceneEl.setAttribute('xrweb', {
      scale: scaleMode,
      allowedDevices: 'any',
      defaultEnvironmentFogIntensity: 0.5,
      defaultEnvironmentFloorTexture: '#groundTex',
      defaultEnvironmentFloorScale: 0.3,
      defaultEnvironmentFloorColor: '#8B8C8D', // #503150
      defaultEnvironmentSkyBottomColor: '#7BA0CE', // #CE98CE
      defaultEnvironmentSkyTopColor: '#60C2F0',
      defaultEnvironmentSkyGradientStrength: 0.3,
    });

    this.cameraHud.classList.remove('hidden');
    this.splashDOM.classList.add('hideAlpha');
    // window.gameManager.startGame()
    window.console.log('splashComponent addXRWeb() Hide me');
  },

  load() {
    if (Utils.paramIsEnabled('stats')) {
      this.el.sceneEl.setAttribute('stats', true);
    }
  },

  getElements() {
    mainMenuContainer = document.querySelector('#main-menu');

    desktopNotSupportedContainer = document.querySelector('#desktop-not-supported');

    browserNotSupportedContainer = document.querySelector('#browser-not-supported');
    unsupportedTrySafary = document.querySelector('#unsupported-try-safary');
    unsupportedTryChrome = document.querySelector('#unsupported-try-chrome');

    allowPermissionsParent = document.querySelector('#allow-permissions-parent');
    permissionsDenied = document.querySelector('#permissions-denied');

    refreshPageBtn = document.querySelector('#refreshPageBtn');
    refreshPageBtn.addEventListener('click', () => {
      location.reload();
    });

    loadingContainer = document.getElementById('loadingContainer');

    startGameButton = document.querySelector('#startBtn');
    startGameButton.addEventListener('click', () => {
      console.log('startGameButton.addEventListener - click, startGameButton.alreadyClicked:', startGameButton.alreadyClicked);
      if (startGameButton.alreadyClicked) return;
      startGameButton.alreadyClicked = true;
      this.pressButtonTween(startGameButton, () => {
        this.startGameClicked();
      });
      window.audioManager.playMusic();
      AnalyticsManager.sendBeginOnLandingEvent();

      // Videos:
      this.jellyfishWallVideo = document.getElementById('jellyfishWallVideo');
      this.oceanWallVideo = document.getElementById('oceanWallVideo');
      this.mountainWallVideo = document.getElementById('mountainWallVideo');
      this.flightWallVideo = document.getElementById('flightWallVideo');
      this.flightKioskWallVideo = document.getElementById('flightKioskWallVideo');

      window.gameManager.startAllVideos();
    });
    setTimeout(() => {
      startGameButton.classList.remove('hideAlphaDown');
    }, 1000);

    denyPermissionsButton = document.querySelector('#deny-permissions-button');
    denyPermissionsButton.addEventListener('click', () => {
      this.pressButtonTween(denyPermissionsButton, () => {
        this.denyPermissionsClicked();
      });
    });

    allowPermissionsButton = document.querySelector('#allow-permissions-button');
    allowPermissionsButton.addEventListener('click', () => {
      if (allowPermissionsButton.alreadyClicked) return;
      allowPermissionsButton.alreadyClicked = true;
      this.pressButtonTween(allowPermissionsButton, () => {
        this.allowPermissionsClicked();
      });
    });
    if (Utils.paramIsEnabled('skipMainMenu')) {
      setTimeout(() => {
        allowPermissionsButton.click();
      }, 100);
    }

    topLogo = document.querySelector('#main-logo');
    const inner = document.querySelector('.inner');
    this.showHideAlpha(inner, false, true);
    setTimeout(() => {
      this.showHideAlpha(inner, true);
    }, 500);

    /*const privacyPolicyButton = document.querySelector('#privacy-policy-button');
    privacyPolicyButton.addEventListener('click', () => {
      this.privacyPolicyButtonClicked();
    });
    const termsOfUseButton = document.querySelector('#terms-of-use-button');
    termsOfUseButton.addEventListener('click', () => {
      this.termsOfUseButtonClicked();
    });
    const cookiePolicyButton = document.querySelector('#cookie-policy-button');
    cookiePolicyButton.addEventListener('click', () => {
      this.cookiePolicyButtonClicked();
    });*/

    buttonSoundElement = document.querySelector('#button-click-audio');
  },

  customizeLoadingPage() {
    loadImageContainer = document.getElementById('loadImageContainer');
    loadImageContainer.style.opacity = 0;
    loadBackground = document.getElementById('loadBackground');
    if (loadBackground) {
      loadBackground.style.backgroundColor = 'rgba(0, 0, 0, 0)';
    }
    const powBy = loadBackground.querySelector('.poweredby-img');
    loadBackground.appendChild(powBy);
    // loadBackground.appendChild(document.querySelector('#main-logo').cloneNode(true));

    const loadingBgOverlay = document.createElement('img');
    loadingBgOverlay.src = loaderOverlayImg;
    loadingBgOverlay.classList.add('loader-overlay');
    loadImageContainer.appendChild(loadingBgOverlay);

    const loadingBgImg = document.createElement('img');
    const loadingImages = [waterImage, boatImage, mountainImage, airplaneImage, spaceImage];
    let currBgImgIdx = 0;
    loadingBgImg.classList.add('loading-bg-image');
    loadImageContainer.appendChild(loadingBgImg);
    const cycleLoadingImg = () => {
      gsap.to(loadingBgImg, {
        opacity: 0,
        duration: 1.0,
        ease: 'power1.in',
        onComplete: () => {
          loadingBgImg.src = loadingImages[currBgImgIdx];
          // console.log('cycleLoadingImg - currBgImgIdx:', currBgImgIdx);
          currBgImgIdx += 1;
          if (currBgImgIdx >= loadingImages.length) currBgImgIdx = 0;
          gsap.to(loadingBgImg, {
            opacity: 1,
            duration: 1.0,
            ease: 'power1.out',
            onComplete: () => {
              cycleLoadingImg();
            },
          });
        },
      });
    };
    cycleLoadingImg();

    this.loaderTextDiv = document.createElement('div');
    this.loaderTextDiv.classList.add('loading-text-message');
    // this.loaderTextDiv.innerHTML = 'Explore uncharted space with digital expertise in augmented reality.';
    this.loaderTextDiv.innerHTML = 'Seeking ancient plans and stories of&nbsp;adventure...';
    loadBackground.appendChild(this.loaderTextDiv);

    const footerImages = document.getElementsByClassName('poweredby-img');
    for (let i = 0; i < footerImages.length; i++) {
      footerImages[i].src = loadingFooterImage;
      // footerImage[i].style.width = '40vw';
      // footerImage[i].style.maxWidth = '220px';
      footerImages[i].style.bottom = '4vh';
    }

    // Add handcrafted image
    /* const handcrafted = document.createElement('img');
    handcrafted.src = handcraftedImage;
    handcrafted.classList.add('handcrafted-logo');
    loadImageContainer.appendChild(handcrafted); */

    let inDom = false;
    const observer = new MutationObserver(() => {
      const promptBox8w = document.querySelector('.prompt-box-8w');
      if (document.querySelector('.prompt-box-8w')) {
        if (!inDom) {
          // Change style
          // promptBox8w.classList.remove('prompt-box-8w');
          promptBox8w.classList.add('allow-permissions-box');
          // promptBox8w.classList.remove('prompt-box-8w');

          const cancelButton = document.querySelector('.prompt-button-8w');
          const confirmBtn = document.querySelector('.button-primary-8w');
          cancelButton.classList.remove('prompt-button-8w');
          confirmBtn.classList.remove('prompt-button-8w');
          confirmBtn.classList.remove('button-primary-8w');
          cancelButton.classList.add('button_permissions');
          confirmBtn.classList.add('button_permissions');
          cancelButton.parentNode.removeChild(cancelButton);
          confirmBtn.parentNode.removeChild(confirmBtn);
          // Update content
          /* promptBox8w.querySelector('p').innerHTML = 'We require access to your camera and sensors to enable augmented&nbsp;reality'; */
          cancelButton.innerHTML = 'cancel';
          confirmBtn.innerHTML = 'ok';

          // promptBox8w.innerHTML = allowPermissionsParent.innerHTML;
          promptBox8w.innerHTML = null;
          promptBox8w.appendChild(allowPermissionsParent);
          allowPermissionsParent.classList.remove('hide');
          allowPermissionsParent.style.opacity = 1;
          allowPermissionsParent.style.height = '100%';
          promptBox8w.style.display = 'contents';

          /* promptBox8w.querySelector('#deny-permissions-button').click = cancelButton.click;
          promptBox8w.querySelector('#allow-permissions-button').click = confirmBtn.click; */
          // confirmBtn.click();
          const btn1 = promptBox8w.querySelector('#deny-permissions-button');
          const btn2 = promptBox8w.querySelector('#allow-permissions-button');
          btn1.remove(); // btn1.parentNode.removeChild(btn1);
          btn2.remove(); // btn2.parentNode.removeChild(btn2);
          const permissionButtonsParent = promptBox8w.querySelector('#permission-buttons-parent');
          permissionButtonsParent.appendChild(cancelButton);
          permissionButtonsParent.appendChild(confirmBtn);
        }
        inDom = true;
      } else if (inDom) {
        inDom = false;
        observer.disconnect();
      }
    });
    observer.observe(document.body, { childList: true });

    ///
    requestingCameraPermissions = document.querySelector('#requestingCameraPermissions');
    const permissionsHeader = document.createElement('img');
    permissionsHeader.src = permissionsHeaderImage;
    permissionsHeader.classList.add('topBg');
    requestingCameraPermissions.appendChild(permissionsHeader);

    ///
    cameraSelectionWorldTrackingError = document.querySelector('#cameraSelectionWorldTrackingError');
    cameraPermissionsErrorApple = document.querySelector('#cameraPermissionsErrorApple');
    cameraPermissionsErrorAppleMessage = document.querySelector('#cameraPermissionsErrorAppleMessage');
    cameraPermissionsErrorAppleMessage.innerHTML = 'Reload Page';

    document.querySelector('p>img').parentNode.remove();
    document.querySelector('.error-text-header').innerHTML = 'Oops!';

    const topLogo2 = this.cameraHud.querySelector('.top-logo').cloneNode(true);
    topLogo2.classList.add('top-logo-loading');
    loadingContainer.appendChild(topLogo2);

    // cameraSelectionWorldTrackingError
    const trackingErrorMessage = document.createElement('div');
    trackingErrorMessage.innerHTML = 'Something went wrong!';
    trackingErrorMessage.classList.add('trackingError');
    cameraSelectionWorldTrackingError.appendChild(trackingErrorMessage);

    const clonedRefreshBtn = refreshPageBtn.cloneNode(true);
    clonedRefreshBtn.id = 'clonedRefreshBtn';
    cameraSelectionWorldTrackingError.appendChild(clonedRefreshBtn);
    clonedRefreshBtn.querySelector('.button_text>img').remove(); // Remove hand icon
    clonedRefreshBtn.addEventListener('click', () => {
      location.reload();
    });

    // cameraPermissionsErrorApple
    const clonedRefreshBtn2 = refreshPageBtn.cloneNode(true);
    clonedRefreshBtn2.id = 'clonedRefreshBtn2';
    cameraPermissionsErrorApple.appendChild(clonedRefreshBtn2);
    clonedRefreshBtn2.querySelector('.button_text>img').remove(); // Remove hand icon
    clonedRefreshBtn2.addEventListener('click', () => {
      location.reload();
    });

    const cameraPermissionsErrorAppleMessage2 = document.createElement('div');
    cameraPermissionsErrorAppleMessage2.innerHTML = 'Reload page and Enable Camera Access';
    cameraPermissionsErrorAppleMessage2.classList.add('trackingError');
    cameraPermissionsErrorApple.insertBefore(cameraPermissionsErrorAppleMessage2, clonedRefreshBtn2);

    // userPromptError
    const userPromptError = loadingContainer.querySelector('#userPromptError');

    const userPromptErrorH1 = userPromptError.querySelector('h1');
    userPromptErrorH1.innerHTML = 'Permissions Were Denied';

    const userPromptErrorImg = document.createElement('img');
    userPromptErrorImg.src = userPromptErrorImage;
    userPromptErrorImg.classList.add('userPromptErrorImg');
    userPromptError.insertBefore(userPromptErrorImg, userPromptErrorH1);

    userPromptError.querySelector('#reloadButton').remove(); // Remove old Reload button
    const clonedRefreshBtn3 = refreshPageBtn.cloneNode(true);
    clonedRefreshBtn3.id = 'clonedRefreshBtn3';
    userPromptError.appendChild(clonedRefreshBtn3);
    clonedRefreshBtn3.querySelector('.button_text>img').remove(); // Remove hand icon
    clonedRefreshBtn3.addEventListener('click', () => {
      location.reload();
    });

    // motionPermissionsErrorApple
    const motionPermissionsErrorApple = loadingContainer.querySelector('#motionPermissionsErrorApple');

    const motionPermissionsErrorAppleH1 = motionPermissionsErrorApple.querySelector('h1');
    motionPermissionsErrorAppleH1.innerHTML = 'Permissions Were Denied';

    const motionPermissionsErrorAppleImg = userPromptErrorImg.cloneNode(true);
    motionPermissionsErrorApple.insertBefore(motionPermissionsErrorAppleImg, motionPermissionsErrorAppleH1);
  },

  resize() {
    if (!rotateDevice) {
      rotateDevice = document.querySelector('#rotate-device');
    }
    // console.log('index resize()');
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    vh = window.innerHeight * 0.01;
    vw = window.innerWidth * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    document.documentElement.style.setProperty('--vw', `${vw}px`);

    this.isCorrectOrientation();
  },

  checkMobileOrDesktop() {
    if (Utils.mobilecheck() || Utils.paramIsEnabled('bypassAR')) {
      // Show unsupported page if
      if (Utils.isiOS()) {
        console.log('checkMobileOrDesktop() Is iOS');
        if (Utils.isUnsupportediOSBrowser() || !XR8.XrDevice.isDeviceBrowserCompatible()) {
          this.showUnsupportedMobile();
        } else {
          this.allowMobileGame();
        }
      } else if (Utils.isAndroid() || Utils.paramIsEnabled('bypassAR')) {
        console.log('checkMobileOrDesktop() Is Android');
        if ((Utils.isUnsupportedAndroidBrowser() || !XR8.XrDevice.isDeviceBrowserCompatible()) && !Utils.paramIsEnabled('bypassAR')) {
          this.showUnsupportedMobile();
        } else {
          this.allowMobileGame();
        }
      } else {
        console.log('checkMobileOrDesktop() Not iOS or Android');
        // Neither iOS nor Android
        this.showUnsupportedMobile();
      }
    }
    else {
      window.preventAppInit = true;
      console.log('checkMobileOrDesktop() - Desktop');
      this.showHideDesktopNotSupportedContainer(true);
      this.splashDOM.style.display = 'none';
      loadingContainer.style.display = 'none';

      // Remove A-Frame scene:
      const scene = document.querySelector('a-scene');
      if (scene) {
        //scene.parentNode.removeChild(scene);
        if (scene.renderer) {
          // aframe already do scene.renderer.xr.dispose(); when the scene is
          // detached but this doesn't stop properly the animation loop,
          // renderer.dispose() calls xr.dispose() and animation.stop()
          scene.renderer.dispose();
        }
      }

      // Remove Rotate Device:
      if (!rotateDevice) {
        rotateDevice = document.querySelector('#rotate-device');
      }
      if (rotateDevice) rotateDevice.remove();
    }
  },

  showHideDesktopNotSupportedContainer(newStatus = true) {
    if (newStatus) desktopNotSupportedContainer.classList.remove('hide');
    else desktopNotSupportedContainer.classList.add('hide');
  },

  showUnsupportedMobile() {
    browserNotSupportedContainer.classList.remove('hide');
    /*if (Utils.isiOS()) {
      unsupportedTrySafary.classList.remove('hide');
      unsupportedTryChrome.classList.add('hide');
    }
    else {
      unsupportedTrySafary.classList.add('hide');
      unsupportedTryChrome.classList.remove('hide');
    }*/
  },

  allowMobileGame() {
    this.isCorrectOrientation();

    // Show main menu
    this.splashDOM.classList.remove('hideAlpha');
  },

  showHideAllowPermissionsParent(newStatus = true) {
    if (newStatus) {
      //allowPermissionsParent.classList.remove('hide');
      this.showHideAlpha(allowPermissionsParent, newStatus);
    }
    else {
      //allowPermissionsParent.classList.add('hide');
      this.showHideAlpha(allowPermissionsParent, newStatus);
    }
  },

  showHidePermissionsDeniedParent(newStatus = true) {
    if (newStatus) {
      //permissionsDenied.classList.remove('hide');
      this.showHideAlpha(permissionsDenied, newStatus);
    }
    else {
      //permissionsDenied.classList.add('hide');
      this.showHideAlpha(permissionsDenied, newStatus);
    }
  },

  isCorrectOrientation() {
    if (vh > vw) { // Portrait
      // console.debug('isCorrectOrientation() Portrait');
      this.leaveIncorrectOrientation();
    }
    else { // Landscape
      // console.debug('isCorrectOrientation() Landscape');
      this.enterIncorrectOrientation();
    }
  },

  enterIncorrectOrientation() {
    // console.log('enterIncorrectOrientation()');
    if (rotateDevice) rotateDevice.classList.remove('hide');
  },

  leaveIncorrectOrientation() {
    // console.log('leaveIncorrectOrientation()');
    if (rotateDevice) rotateDevice.classList.add('hide');
  },

  startGameClicked() {
    this.splashDOM.classList.add('hideAlpha');
    if (!Utils.isiOS()) {
      this.showHideAllowPermissionsParent(true);
    }
    else {
      // loadBackground.querySelector('#main-logo').classList.add('hideAlpha');
      this.allowPermissionsClicked();
    }
    this.showHideAlpha(startGameButton, false);
  },

  denyPermissionsClicked() {
    //const newWindow = window.open(GameData.siteUrl, '_blank');
    //if (newWindow) newWindow.focus();
    this.showHideAllowPermissionsParent(false);
    this.showHidePermissionsDeniedParent(true);
  },

  allowPermissionsClicked() {
    //mainMenuContainer.classList.add('hide');
    this.showHideAllowPermissionsParent(false);

    // Show loading screen:
    // this.showHideAlpha(loadImageContainer, true);
    // this.loaderTextDiv.innerHTML = 'Seeking ancient plans and stories of&nbsp;adventure...';

    setTimeout(() => {
      if (!Utils.paramIsEnabled('bypassAR')) {
        this.addXRWeb();
      }
      else {
        // Bypass AR
        const scene = new THREE.Scene();
        scene.background = new THREE.Color(0x8198a6);
        const camera = new THREE.PerspectiveCamera(95, window.innerWidth / window.innerHeight, 0.1, 100);
        scene.add(camera);
        camera.position.set(0, 3, 0);
        camera.rotateX(Math.PI * -0.32);

        const camerafeed = document.getElementById('camerafeed');
        if (camerafeed) camerafeed.remove();

        const renderer = new THREE.WebGLRenderer({
          antialias: true,
        });
        renderer.setSize(window.innerWidth, window.innerHeight);
        const canvas = renderer.domElement;
        document.body.appendChild(canvas);
        //
        this.addXRWeb();
      }

      //
      let promptContainer;
      let loadImage;
      let btnContainer;
      this.getElementsLoop = setInterval(() => { // Loop
        promptContainer = document.getElementsByClassName('prompt-box-8w')[0];
        loadImage = document.getElementById('loadImage');
        btnContainer = document.getElementsByClassName('prompt-button-container-8w')[0];
        if (promptContainer && loadImage && btnContainer) {
          if (this.getElementsLoop !== null) clearInterval(this.getElementsLoop); // Stop loop
          console.log('startGameClicked() - btnContainer:', btnContainer);
          const denyPermissionBtn = btnContainer.children[0];
          console.log('startGameClicked() denyPermissionBtn:', denyPermissionBtn);
          const btnClone = denyPermissionBtn.cloneNode(true); // Clone button to remove event listeners
          denyPermissionBtn.parentNode.replaceChild(btnClone, denyPermissionBtn);
          btnClone.addEventListener('click', () => {
            this.showHideAlpha(promptContainer, 0);
            this.showHideAlpha(loadImage, 0, true);
            this.splashDOM.classList.remove('hideAlpha');
            this.denyPermissionsClicked();
          });
        }
      }, 100);

      setTimeout(() => {
        if (this.getElementsLoop !== null) clearInterval(this.getElementsLoop); // Stop loop
      }, 6000);
    }, 300);
  },

  onBeforeRun() {
    console.log('onBeforeRun()');
  },

  onStart() {
    console.log('onStart()');
    // Show loading screen:
    this.showHideAlpha(loadImageContainer, true);
    this.loaderTextDiv.innerHTML = 'Seeking ancient plans and stories of&nbsp;adventure...';
  },

  playButtonSound() {
    //buttonSoundElement.pause();
    buttonSoundElement.currentTime = 0;
    buttonSoundElement.play();
  },

  privacyPolicyButtonClicked() {
    // const newWindow = window.open('https://privacy.paramount.com/childrens', '_blank');
    // if (newWindow) newWindow.focus();
  },

  termsOfUseButtonClicked() {
    // const newWindow = window.open('https://www.nick.com/legal/ussoge/terms-of-use', '_blank');
    // if (newWindow) newWindow.focus();
  },

  cookiePolicyButtonClicked() {
    // const newWindow = window.open('https://privacy.paramount.com/cookies', '_blank');
    // if (newWindow) newWindow.focus();
  },

  // TWEENS>
  showHideFromUnder(element, newValue = true, instant = false) {
    if (newValue) {
      gsap.fromTo(element,
        {
          opacity: 0,
          y: 20,
        },
        {
          duration: instant ? 0 : 0.5,
          opacity: 1,
          y: 0,
          ease: 'power2.out',
          onStart: () => {
            element.classList.remove('hide');
          },
        },
      );
    }
    else {
      gsap.to(element, {
        duration: instant ? 0 : 0.5,
        y: 20,
        opacity: 0,
        ease: 'power2.in',
        onComplete: () => {
          element.classList.add('hide');
        },
      });
    }
  },

  showHideAlpha(element, newValue = true, instant = false) {
    //console.log('showHideAlpha - element', element);
    if (newValue) {
      gsap.fromTo(element,
        {
          opacity: 0,
        },
        {
          duration: instant ? 0 : 0.25,
          opacity: 1,
          ease: 'sine.out',
          onStart: () => {
            element.classList.remove('hide');
          },
        },
      );
    }
    else {
      gsap.to(element, {
        duration: instant ? 0 : 0.25,
        opacity: 0,
        ease: 'sine.in',
        onComplete: () => {
          element.classList.add('hide');
        },
      });
    }
  },

  pressButtonTween(element, callback = null, instant = false) {
    if (element.isTweening) return;
    element.isTweening = true;
    this.playButtonSound();
    // Tween
    gsap.fromTo(element,
      {
        scale: 1,
      },
      {
        duration: instant ? 0 : 0.04,
        scale: 1,
        ease: 'sine.out',
        yoyo: true,
        repeat: 1,
        onComplete: () => {
          if (callback && typeof callback === 'function') callback.call();
          element.isTweening = false;
        },
      },
    );
    // Callback
  },
  // <TWEENS
};

export { splashComponent };
