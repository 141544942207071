import mainImage1 from '../assets/ui-images/main-image-1.jpg';
import mainImage2 from '../assets/ui-images/main-image-2.jpg';
import mainImage3 from '../assets/ui-images/main-image-3.jpg';
import mainImage4 from '../assets/ui-images/main-image-4.jpg';
import mainImage5 from '../assets/ui-images/main-image-5.jpg';

import roomIconImage1 from '../assets/ui-images/info_box_icon_room1.png';
import roomIconImage2 from '../assets/ui-images/info_box_icon_room2.png';
import roomIconImage3 from '../assets/ui-images/info_box_icon_room3.png';
import roomIconImage4 from '../assets/ui-images/info_box_icon_room4.png';
import roomIconImage5 from '../assets/ui-images/info_box_icon_room5.png';

const poiDataJson = {
  theDeep: {
    poiIcon: 'poi_1.png',
    poiNumber: '01',
    poiTitle: 'Gallery of the Deep',
    bigHeader: 'Hologram\nTheatre',
    roomIcon: roomIconImage1,
    mainText: 'Life-like illusions that bring unreal sights into reality with a blend of digital and physical magic.',
    bodyText: 'Unleash dynamic holograms and programmatic object theater shows to lead your guests on unforgettable educational adventures that keep ‘em coming back for more.',
    mainImage: mainImage1,
    mainImageCaption: 'Fig 01a. Nefrina Exhibition',
    // section2SmallText: 'See it in action',
    section2Title: 'Nefrina',
    descriptionText: 'Hear the personal history of Nefrina, a 2500 year old mummy, in her own words as she comes to life in this installation created with Reading Public Museum.',
    ctaLink: 'https://www.neopangea.com/portfolio/reading-public-museum/nefrina-a-living-hologram.html',
    // galleryImages: ['gallery_placeholder.png', 'gallery_placeholder.png', 'gallery_placeholder.png', 'gallery_placeholder.png'],
    // bulletPoints: ['Bullet point 1', 'Bullet point 2', 'Bullet point 3', 'Bullet point 4', 'Bullet point 5'],
  },
  seas: {
    poiIcon: 'poi_2.png',
    poiNumber: '02',
    poiTitle: 'Hall of Seas',
    bigHeader: 'Immersive\nProjection',
    roomIcon: roomIconImage2,
    mainText: 'Artfully applied graphics and animations that redefine your surroundings.',
    bodyText: 'Like magic, projection can immerse your audience in new worlds, transport them across time, and turn a once mundane exhibit into a crowd-pleasing word-of-mouth success.',
    mainImage: mainImage2,
    mainImageCaption: 'Fig 02a. Tree of Hope Projection',
    // section2SmallText: 'See it in action',
    section2Title: 'Becoming Jane',
    descriptionText: 'Make an environmental pledge and watch it revitalize nature in this hands-on multimedia exhibition created with National Geographic.',
    ctaLink: 'https://www.neopangea.com/portfolio/national-geographic/becoming-jane-exhibit.html',
    // galleryImages: ['gallery_placeholder.png', 'gallery_placeholder.png', 'gallery_placeholder.png', 'gallery_placeholder.png'],
    // bulletPoints: ['Bullet point 1', 'Bullet point 2', 'Bullet point 3', 'Bullet point 4', 'Bullet point 5'],
  },
  newHeights: {
    poiIcon: 'poi_3.png',
    poiNumber: '03',
    poiTitle: 'Gallery of New Heights',
    bigHeader: 'Animation &\nVideo',
    roomIcon: roomIconImage3,
    mainText: 'Movement that moves you, from eye-catching animations to awe-inducing videos.',
    bodyText: 'By harnessing the power of animation, video, and audio, you transform a static exhibit into a sensory spectacle, delighting audiences with sights and sounds from history.',
    mainImage: mainImage3,
    mainImageCaption: 'Fig 03a. Egyptian God Family Tree',
    // section2SmallText: 'See it in action',
    section2Title: "Discovering King Tut's Tomb",
    descriptionText: "Follow the legendary discovery of King Tut's tomb and learn about the boy king's life through animated media moments created with Imagine Exhibitions.",
    ctaLink: 'https://www.neopangea.com/portfolio/imagine-exhibitions/discovering-king-tuts-tomb.html',
    // galleryImages: ['gallery_placeholder.png', 'gallery_placeholder.png', 'gallery_placeholder.png', 'gallery_placeholder.png'],
    // bulletPoints: ['Bullet point 1', 'Bullet point 2', 'Bullet point 3', 'Bullet point 4', 'Bullet point 5'],
  },
  skies: {
    poiIcon: 'poi_4.png',
    poiNumber: '04',
    poiTitle: 'Hall of Skies',
    bigHeader: 'Touch\nSurface',
    roomIcon: roomIconImage4,
    mainText: "Screens that go beyond a story's surface with accessible interactivity.",
    bodyText: 'Touching hearts and minds can be achieved with dazzling touch instructive surface attractions. It’s a powerful way to deepen engagement by putting the power of choice in their hands.',
    mainImage: mainImage4,
    mainImageCaption: 'Fig 04a. Potions Interactive',
    // section2SmallText: 'See it in action',
    section2Title: 'Harry Potter: The Exhibition',
    descriptionText: 'Reach out and interact with iconic moments from Harry Potter™ and the Wizarding World in this globe-trotting exhibition created with Imagine Exhibitions.',
    ctaLink: 'https://www.neopangea.com/portfolio/imagine-exhibitions/harry-potter-the-exhibition.html',
    // galleryImages: ['gallery_placeholder.png', 'gallery_placeholder.png', 'gallery_placeholder.png', 'gallery_placeholder.png'],
    // bulletPoints: ['Bullet point 1', 'Bullet point 2', 'Bullet point 3', 'Bullet point 4', 'Bullet point 5'],
  },
  universe: {
    poiIcon: 'poi_5.png',
    poiNumber: '05',
    poiTitle: 'The Universe Rotunda',
    bigHeader: 'Virtual &\nMixed Reality',
    roomIcon: roomIconImage5,
    mainText: 'Immersive worlds and interactive feats best explored with a headset or mobile device.',
    bodyText: 'Augmented and Virtual Reality is the new frontier of experience. Embrace it, and make your exhibits come to life in ways that stimulate the senses and leave them catching their breath.',
    mainImage: mainImage5,
    mainImageCaption: 'Fig 05a. Life Support Repair Simulation',
    // section2SmallText: 'See it in action',
    section2Title: 'Make Mars Home',
    descriptionText: 'Experience the lives of Mars colonists firsthand through a series of mission simulations in this alternate reality campaign created with National Geographic.',
    ctaLink: 'https://www.neopangea.com/portfolio/national-geographic-channel/make-mars-home.html',
    // galleryImages: ['gallery_placeholder.png', 'gallery_placeholder.png', 'gallery_placeholder.png', 'gallery_placeholder.png'],
    // bulletPoints: ['Bullet point 1', 'Bullet point 2', 'Bullet point 3', 'Bullet point 4', 'Bullet point 5'],
  },
};
export default poiDataJson;
