// This component is an example of how to separate behavior by device category
// using 8th Wall Engine sessionAttributes

const responsiveImmersiveComponent = {
  init() {
    console.log('responsiveImmersiveComponent init()');
    const onAttach = ({ sessionAttributes }) => {
      console.log('onAttach - sessionAttributes:', sessionAttributes);
      const aCamera = document.getElementById('camera');
      const threeCamera = aCamera.getObject3D('camera');
      const imageTarget = document.getElementById('imageTarget');
      const objectModel = document.getElementById('objectModel');
      // const hotspots = document.getElementById('hotspot-group')
      const s = sessionAttributes;
      if (
        !s.cameraLinkedToViewer
        && !s.controlsCamera
        && !s.fillsCameraTexture
        && !s.supportsHtmlEmbedded
        && s.supportsHtmlOverlay
        && !s.usesMediaDevices
        && !s.usesWebXr
      ) { // Desktop-specific behavior goes here
        // hotspots.parentNode.removeChild(hotspots)  // remove hotspots
        const addComponents = () => {
          // vrModel.setAttribute('change-color', '')
          vrModel.setAttribute('cubemap-static', '');
          // vrModel.setAttribute('xrextras-pinch-scale', '')
        };
        // vrModel.getObject3D('mesh') ? addComponents() : vrModel.addEventListener('model-loaded', addComponents)
        threeCamera.fov = 72;
        threeCamera.updateProjectionMatrix();
        window.gameManager.adjustDesktopHierarchy();

        objectModel.setAttribute('rotation', { x: 0, y: 270, z: 0 });
        window.isDesktop = true;
      } else if (
        s.cameraLinkedToViewer
        && s.controlsCamera
        && !s.fillsCameraTexture
        && s.supportsHtmlEmbedded
        && !s.supportsHtmlOverlay
        && !s.usesMediaDevices
        && s.usesWebXr
      ) { // HMD-specific behavior goes here
        // hotspots.parentNode.removeChild(hotspots)  // remove hotspots
        if (this.el.sceneEl.xrSession.environmentBlendMode === 'opaque') {
          // VR HMD (i.e. Oculus Quest) behavior goes here
          vrModel.setAttribute('ignore-raycast', '');
          const addComponents = () => {
            vrModel.setAttribute('change-color', '');
            vrModel.setAttribute('cubemap-static', '');
          };
          // vrModel.getObject3D('mesh') ? addComponents() : vrModel.addEventListener('model-loaded', addComponents)
        } else if (this.el.sceneEl.xrSession.environmentBlendMode === 'additive' || 'alpha-blend') {
          // AR HMD (i.e. Hololens) behavior goes here
          vrModel.setAttribute('ignore-raycast', '');
          const addComponents = () => {
            vrModel.setAttribute('change-color', '');
            vrModel.setAttribute('cubemap-static', '');
          };
          // vrModel.getObject3D('mesh') ? addComponents() : vrModel.addEventListener('model-loaded', addComponents)
        }
      } else if (
        !s.cameraLinkedToViewer
        && !s.controlsCamera
        && s.fillsCameraTexture
        && !s.supportsHtmlEmbedded
        && s.supportsHtmlOverlay
        && s.usesMediaDevices
        && !s.usesWebXr
      ) { // Mobile-specific behavior goes here
        XR8.XrController.configure({ imageTargets: ['tracker'] }); // Load Image Target

        window.addEventListener('xrimagefound', (e) => {
          window.gameManager.xrimagefound(e);
        });

        window.addEventListener('xrimagelost', (e) => {
          window.gameManager.xrimagelost(e);
        });

        const addComponents = () => {
          vrModel.setAttribute('change-color', '');
          vrModel.setAttribute('cubemap-realtime', '');
        };
        // vrModel.getObject3D('mesh') ? addComponents() : vrModel.addEventListener('model-loaded', addComponents)
      }
      window.console.log('responsiveImmersiveComponent onAttach() window.isDesktop', window.isDesktop);
      window.gameManager.startGame();
    };

    const onxrloaded = () => {
      console.log('responsiveImmersiveComponent onxrloaded()');
      XR8.addCameraPipelineModules([{
        name: 'responsiveImmersive',
        onAttach,
        onBeforeRun: () => {
          window.splashComponentRef.onBeforeRun();
        },
        onStart: () => {
          window.splashComponentRef.onStart();
        },
      }]);
    };
    window.XR8 ? onxrloaded() : window.addEventListener('xrloaded', onxrloaded);
  },
};

export { responsiveImmersiveComponent };
