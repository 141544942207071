const drawOnTop = {
  schema: {
    renderOrder: { default: 1 },
    alphaCutout: { default: true },
  },

  init() {
    this.el.sceneEl.renderer.sortObjects = true;
    this.el.object3D.renderOrder = this.data.renderOrder;
    // this.el.components.material.material.depthTest = false;
    if (this.data.alphaCutout) {
      this.el.components.material.material.alphaToCoverage = true;
      this.el.components.material.material.alphaTest = 0.5;
    }
  },
};

export { drawOnTop };
