export default class Utils {
  static checkParams() {
    // Get querystring:
    const querystring = window.location.search;
    // Create URLSearchParams from querystring:
    this.params = new URLSearchParams(querystring);
  }

  static paramIsEnabled(param = '') {
    if (!this.params) Utils.checkParams();
    // console.log('Utils - paramIsEnabled()', param, this.params.get(param) === 'true' || this.params.get(param) === '1');
    return this.params.get(param) === 'true' || this.params.get(param) === '1';
  }

  static getParamValue(param = '') {
    if (!this.params) Utils.checkParams();
    return this.params.get(param);
  }

  static getQueryString(key, default_) {
    if (default_ == null) {
      default_ = '';
    }
    key = key.replace(/[\[]/, '\\\[').replace(/[\]]/, '\\\]');
    const regex = new RegExp(`[\\?&]${key}=([^&#]*)`);
    const qs = regex.exec(window.location.href);
    if (qs == null) {
      return default_;
    } return qs[1];
  }

  static isUnsupportediOSBrowser() {
    const ua = window.navigator.userAgent;
    // var webkit = !!ua.match(/WebKit/i);
    const isChrome = ua.match(/Chrome/i) != null || ua.match(/CriOS/i) != null;
    const isFirefox = ua.match(/Firefox/i) != null || ua.match(/FxiOS/i) != null;
    const isSafari = ua.indexOf('Safari') != -1 && !isChrome;

    const isUnsupported = (Utils.isiOS() && (!isSafari && !isChrome && !isFirefox)) || Utils.getQueryString('unsupported') == 1;
    // window.console.log('Utils - isUnsupportediOSBrowser() - isChrome', isChrome, ', isFirefox:', isFirefox, ', isSafari:', isSafari, '  -   isUnsupported:', isUnsupported);
    return isUnsupported;
    // return Utils.isiOS() && !iOSSafari;
  }

  static isUnsupportedNonIOSBrowser() {
    const ua = window.navigator.userAgent;
    return Utils.getQueryString('unsupported') == 1;
  }

  static isiOS() {
    const ua = window.navigator.userAgent;
    const isSafari = ua.indexOf('Safari') != -1;
    const isAndroid = ua.match(/Android/i) != null;
    if (isAndroid) return false;

    const isiOSTouch = isSafari && navigator.maxTouchPoints > 1;
    const result = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i) || isiOSTouch;
    console.log('Utils - isiOS() - result:', result);
    return result;
  }

  static isAndroid() {
    const ua = window.navigator.userAgent;
    const isAndroid = ua.match(/Android/i) != null;
    // alert(ua);
    return isAndroid;
  }

  static isUnsupportedAndroidBrowser() {
    const ua = window.navigator.userAgent;

    // List the browser that are safe:
    const isFireFox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    const isChrome = ua.match(/Chrome/i) || ua.match(/CriOS/i);
    const isOpera = ua.indexOf('OP') > -1;
    const isEdge = ua.indexOf('Edg') > -1;
    const isKindle = ua.match(/Kindle/i) || ua.match(/Silk/i) || ua.match(/KFTT/i) || ua.match(/KFOT/i) || ua.match(/KFJWA/i) || ua.match(/KFJWI/i) || ua.match(/KFSOWI/i) || ua.match(/KFDOWI/i) || ua.match(/KFTHWA/i) || ua.match(/KFTHWI/i) || ua.match(/KFAPWA/i) || ua.match(/KFAPWI/i);

    return (!isFireFox && !isChrome) || isEdge || isOpera || isKindle || Utils.getQueryString('unsupported') == 1;
  }

  static detectTouchscreen() {
    let result = false;
    if (window.PointerEvent && ('maxTouchPoints' in navigator)) {
      // if Pointer Events are supported, just check maxTouchPoints
      if (navigator.maxTouchPoints > 0) {
        result = true;
      }
    } else {
      // no Pointer Events...
      if (window.matchMedia && window.matchMedia('(any-pointer:coarse)').matches) {
        // check for any-pointer:coarse which mostly means touchscreen
        result = true;
      } else if (window.TouchEvent || ('ontouchstart' in window)) {
        // last resort - check for exposed touch events API / event handler
        result = true;
      }
    }
    return result;
  }

  static mobilecheck() {
    // Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/86.0.4240.198 Safari/537.36
    const ua = navigator.userAgent;
    const winDesktop = ua.match(/Windows/i) != null || ua.match(/Win64/i) != null;
    const iOS = Utils.isiOS();
    const isiPad = ua.match(/iPad/i) != null;
    const isiPhone = !!((ua.match(/iPhone/i) != null || Utils.getQueryString('isiPhone') == 'true'));
    const isiPhoneX = (isiPhone && window.innerHeight >= 635);
    const isiPod = ua.match(/iPod/i) != null;
    const isAndroid = ua.match(/Android/i) != null;
    const isMobileWebkit = /WebKit/.test(ua) && /Mobile/.test(ua);
    const isAndroidTablet = (isAndroid && ua.match(/Mobile/i) == null); // || getQueryString("isAndroidTablet") == "true" ) ? true : false,,
    const isAndroidPhone = !!((isAndroid && !isAndroidTablet));
    const isTablet = isiPad || isAndroidTablet;
    const isIE11 = (Object.hasOwnProperty.call(window, 'ActiveXObject') && !window.ActiveXObject); // detect 10- using user agent, 11 with feature detection,
    const isIE = ua.match(/MSIE/i) != null || ua.match(/Trident/i) != null || isIE11; // detect 10- using user agent, 11 with feature detection,
    const isChrome = ua.match(/Chrome/i) != null;
    const isIosChrome = ua.match('CriOS') != null;
    const isSafari = ua.indexOf('Safari') != -1 && !isChrome;
    const isFireFox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    const clickEvent = (isiPad || isiPhone || isAndroid || isMobileWebkit) ? 'touchend' : 'click';
    const isiOSTouch = isSafari && navigator.maxTouchPoints > 1;
    const multitouch = navigator.maxTouchPoints > 1;
    const isTouch = Utils.detectTouchscreen();
    const isMobile = (isTouch || iOS || isiPad || isiOSTouch || isiPhone || isiPod || isAndroid || isMobileWebkit || multitouch); // || getQueryString("isMobile") == "true" ) ? true : false,

    // window.console.debug("winDesktop", winDesktop);
    window.console.log('Utils - mobilecheck() - winDesktop:', winDesktop, ', isMobile:', isMobile);
    if (winDesktop) return false;

    return isMobile;
  }

  static getRandomInt(min = 0, max = 1) {
    return Math.round(Math.random() * (max - min) + min); // max inclusive
  }

  static getRandomFloat(min = 0, max = 1) {
    return Math.random() * (max - min) + min;
  }

  static supportsHEVCAlpha() {
    const ua = window.navigator.userAgent.toLowerCase();
    const hasMediaCapabilities = !!(window.navigator.mediaCapabilities && window.navigator.mediaCapabilities.decodingInfo);
    const isSafari = ua.indexOf('safari') != -1 && !(ua.indexOf('chrome') != -1) && ua.indexOf('version/') != -1;
    return isSafari && hasMediaCapabilities;
  }
}
