/* eslint-disable arrow-body-style */
import gsap from 'gsap';

const poiTriggerNewHeights = {
  schema: {
  },

  init() {
    this.el.addEventListener('click', () => {
      console.log('poiTriggerNewHeights - click');
      this.isClicked = true;
    });

    this.el.addEventListener('mouseenter', () => {
      console.log('poiTriggerNewHeights - mouseenter');
    });

    this.el.addEventListener('mouseleave', () => {
      console.log('poiTriggerNewHeights - mouseleave');
      if (this.isClicked) return;
      this.leavePoint();
    });

    this.el.addEventListener('poiclosed', () => {
      console.log('poiTriggerNewHeights - poiclosed');
      this.leavePoint();
    });
  },

  leavePoint() {
    this.isClicked = false;
  },
};

export { poiTriggerNewHeights };
