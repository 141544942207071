import AnalyticsManager from './analytics-manager.js';

export default class FormManager {
  static initForm() {
    this.FORM_ENDPOINT = 'https://www.neopangea.com/ar-mail-handler.html';
    this.FORM = document.getElementById('contact-form');

    this.FORM.addEventListener('submit', this.handleFormSubmit.bind(this));

    this.formSubmitBtn = document.getElementById('formSubmit');
    this.formSubmitBtn.addEventListener('click', this.submitBtnPressed.bind(this));
  }

  static submitBtnPressed() {
    AnalyticsManager.sendSubmitFormEvent();
    this.FORM.requestSubmit();
  }

  /**
   * @function handleFormSubmit
   * @param { Object } evt
   */
  static handleFormSubmit(evt) {
    evt.preventDefault();

    if (this.FORM.checkValidity()) {
      const FORM_DATA = new FormData();

      FORM_DATA.append('fname', document.querySelector('input[name="fname"]').value);
      FORM_DATA.append('lname', document.querySelector('input[name="lname"]').value);
      FORM_DATA.append('company', document.querySelector('input[name="company"]').value);
      FORM_DATA.append('email', document.querySelector('input[name="email"]').value);
      FORM_DATA.append('message', document.querySelector('textarea[name="message"]').value);
      const newsletterOptinInput = document.querySelector('input[name="newsletter_optin"]:checked');
      FORM_DATA.append('newsletter_optin', newsletterOptinInput ? newsletterOptinInput.value : '');
      FORM_DATA.append('mailinglist', document.querySelector('input[name="mailinglist"]').value);
      FORM_DATA.append('workemail', document.querySelector('input[name="workemail"]').value);

      // FORM_DATA.entries().forEach((formEntry) => console.log('FormManager - handleFormSubmit() - FORM_DATA entry:', formEntry));

      fetch(this.FORM_ENDPOINT, {
        method: 'POST',
        body: FORM_DATA,
      })
        .then((res) => {
          console.log('Form submission successful.');
          console.log(res);

          this.FORM.reset();
          alert('Thank you for your form submission.');
        })
        .catch((err) => {
          console.log('An error occurred with the form submission.');
          console.log(err);
        });
    }
  }
}
