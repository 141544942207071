/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
/* global gtag */

export default class AnalyticsManager {
  static sendOnboardingCompletedEvent() {
    console.log('%cAnalyticsManager sendOnboardingCompletedEvent()', 'color: green; background: black;');
    gtag('event', 'onboarding_completed');
  }

  static sendViewMuseumEvent() {
    console.log('%cAnalyticsManager sendViewMuseumEvent()', 'color: green; background: black;');
    gtag('event', 'view_museum');
  }

  static sendRoomSelectedEvent(selectedRoom) {
    console.log(`%cAnalyticsManager sendRoomSelectedEvent() - selectedRoom: ${selectedRoom}`, 'color: green; background: black;');
    gtag('event', 'select_room', {
      room: selectedRoom,
    });
  }

  static sendCaseStudyClickedEvent(selectedCase) {
    console.log(`%cAnalyticsManager sendCaseStudyClickedEvent() - selectedCase: ${selectedCase}`, 'color: green; background: black;');
    gtag('event', 'case_study_select', {
      case_study: selectedCase,
    });
  }

  static sendContactClickedEvent() {
    console.log('%cAnalyticsManager sendContactClickedEvent()', 'color: green; background: black;');
    gtag('event', 'contact_clicked');
  }

  static sendSubmitFormEvent() {
    console.log('%cAnalyticsManager sendSubmitFormEvent()', 'color: green; background: black;');
    gtag('event', 'form_submit');
  }

  static sendBeginOnLandingEvent() {
    console.log('%cAnalyticsManager sendBeginOnLandingEvent()', 'color: green; background: black;');
    gtag('event', 'Begin_on_Landing');
  }

  static sendActivateARNoCardEvent() {
    console.log('%cAnalyticsManager sendActivateARNoCardEvent()', 'color: green; background: black;');
    gtag('event', 'Activate_AR_No_Card');
  }

  static sendActivateARYesCardEvent() {
    console.log('%cAnalyticsManager sendActivateARYesCardEvent()', 'color: green; background: black;');
    gtag('event', 'Activate_AR_Yes_Card');
  }
}

/*
1. Time spent -> Not manually implemented
2. Did people make it though the onboarding? -> `onboarding_completed`
3. Did people get to see the 3d museum model or did they drop before that? -> `view_museum`
4. How many used the contact form? -> `contact_clicked` when the "contact" button is pressed
5. Which points of interest/rooms they clicked -> `select_room` (Should include a `room` parameter to specify which one)
6. How many people clicked through the site links and to which case studies -> `case_study_select` (Should include a `case_study` parameter to specify which one)
7. Can we track the contact form submission within the app so we can see if they complete the form or visit our website via the contact modal? -> `form_submit` when the form is submitted
8. Hit "Begin" on the landing -> `Begin_on_Landing`
9. If the user hits the button to activate it with NO card -> `Activate_AR_No_Card`
10. If it sees the card (just once) -> `Activate_AR_Yes_Card`
*/
